import React from 'react'
import { Card, Table, Typography } from '../Atoms'
import { useTranslations } from '../../context/TranslationsContext'


export const Natures = () => {
    const { t } = useTranslations()
    return (
        <Card>
            <Typography as="h5">Natures</Typography>
            <Table size='sm' striped="columns" className="IVtable" responsive>
                <thead>
                    <tr>
                        <th style={{ width: '20%' }} ></th>
                        <th style={{ width: '16%' }} >{'- ' + t('attack')}</th>
                        <th style={{ width: '16%' }} >{'- ' + t('defense')}</th>
                        <th style={{ width: '16%' }} >{'- ' + t('sp. attack')}</th>
                        <th style={{ width: '16%' }} >{'- ' + t('sp. defense')}</th>
                        <th style={{ width: '16%' }} >{'- ' + t('speed')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{'+ ' + t('attack')}</td>
                        <td>{t('hardy')}</td>
                        <td>{t('lonely')}</td>
                        <td>{t('adamant')}</td>
                        <td>{t('naughty')}</td>
                        <td>{t('brave')}</td>
                    </tr>
                    <tr>
                        <td>{'+ ' + t('defense')}</td>
                        <td>{t('bold')}</td>
                        <td>{t('docile')}</td>
                        <td>{t('impish')}</td>
                        <td>{t('lax')}</td>
                        <td>{t('relaxed')}</td>
                    </tr>
                    <tr>
                        <td>{'+ ' + t('sp. attack')}</td>
                        <td>{t('modest')}</td>
                        <td>{t('mild')}</td>
                        <td>{t('bashful')}</td>
                        <td>{t('rash')}</td>
                        <td>{t('quiet')}</td>
                    </tr>
                    <tr>
                        <td>{'+ ' + t('sp. defense')}</td>
                        <td>{t('calm')}</td>
                        <td>{t('gentle')}</td>
                        <td>{t('careful')}</td>
                        <td>{t('quirky')}</td>
                        <td>{t('sassy')}</td>
                    </tr>
                    <tr>
                        <td>{'+ ' + t('speed')}</td>
                        <td>{t('timid')}</td>
                        <td>{t('hasty')}</td>
                        <td>{t('jolly')}</td>
                        <td>{t('naive')}</td>
                        <td>{t('serious')}</td>
                    </tr>
                </tbody>
            </Table>
        </Card>
    )
}